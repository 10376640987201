<template>
    <setting-layout :title="$t('pages.user.setting.creator.withdraw_options.withdraw.bitsafe.title')">
        <v-container>
            <v-form>
                <v-row>
                    <v-col cols="12">
                        <div class="pb-1">{{$t(`${bitsafeLangPath}.full_name`)}}</div>
                        <text-field v-model="model.full_name"/>
                    </v-col>
                    <v-col cols="12">
                        <div class="pb-1">{{$t(`${bitsafeLangPath}.country`)}}</div>
                        <v-select
                            background-color="rgba(237,237,237, .1)"
                            color="orange"
                            :items="countries"
                            solo
                            flat
                            item-color="orange"
                            item-text="text"
                            item-value="val"
                            v-model="model.country"
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <div class="pb-1">{{$t(`${bitsafeLangPath}.iban`)}}</div>
                        <text-field v-model="model.iban"/>
                    </v-col>
                </v-row>
                <bottom-control-block :checked="isDefault"
                                      days-receive="1-3"
                                      max-days-receive="3"
                                      :processing-fee="1"
                                      min-payment="50"
                                      @change="changeDefault"
                                      :loading="$store.getters.loadingBtn(['setting', 'withdraw', 'directBtn'])"
                                      @done="save">
                </bottom-control-block>
            </v-form>
        </v-container>

        <WithdrawalFormErrorDialog
            v-if="this.isShowWithdrawFormErrorDialog"
            @close="handleCloseWithdrawErrorFormDialog"
        >
            <template #title>
                {{ $t('dialogs.withdrawal_form_error.bitsafe.title') }}
            </template>

            <template #description>
                {{ $t('dialogs.withdrawal_form_error.bitsafe.description') }}
            </template>
        </WithdrawalFormErrorDialog>
    </setting-layout>
</template>

<script>
import BottomControlBlock from "@/components/creator/BottomControlBlock";
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import { mapActions, mapMutations } from "vuex";
import WithdrawalFormErrorDialog from "@/components/app/dialogs/WithdrawalFormError.vue";
import countriesSelect from '@/configs/countriesSelect';

export default {
    name: "Bitsafe",
    components: {WithdrawalFormErrorDialog, TextField, SettingLayout, BottomControlBlock},
    data() {
        return {
            model: {
                iban: '',
                full_name: '',
                country: '',
            },
            isDefault: this.$store.state.user.user.data.withdrawDefault === 'bitsafe',
            isShowWithdrawFormErrorDialog: false,
        }
    },
    computed: {
        bitsafeLangPath(){
            return 'pages.user.setting.creator.withdraw_options.withdraw.bitsafe'
        },
        countries() {
            return countriesSelect;
        }
    },
    methods: {
        ...mapMutations(["showSnackBar"]),
        ...mapActions([
            'getBitsafe',
            'sendBitsafe',
        ]),
        changeDefault() {
            this.$store.state.user.user.data.withdrawDefault = this.isDefault ? 'bitsafe' : null
        },
        validateForm(form) {
            for (const key in form) {
                if (form.hasOwnProperty(key)) {
                    const value = form[key]
                    if (value === null || value === undefined || value === '') {
                        return false
                    }
                }
            }
            return true
        },
        save() {
            this.model.default = !this.isDefault

            const isValid = this.validateForm(this.model);
            if (!isValid) {
                this.isShowWithdrawFormErrorDialog = true;
                return
            }

            this.sendBitsafe({model: this.model})
                .then((result) => {
                    this.success = result || {}
                    this.errors = {}
                    this.showSnackBar(this.$t("dialogs.payment_method.saved"));
                }, (error) => {
                    this.errors = error.response.data.errors || {}
                    this.showSnackBar(this.$t("dialogs.payment_method.not_saved"));
                })
        },
        handleCloseWithdrawErrorFormDialog() {
            this.isShowWithdrawFormErrorDialog = false
        },
    },
    mounted() {
        this.getBitsafe().then((response) => {
            this.model = response.data || {}
        }, (error) => {
            console.log(error)
        })
    },
}
</script>

<style scoped>

</style>
